/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import i18n from '../../../i18n';
import ArrowDown from '../../../assets/icons/ArrowDown';
import GlobeIcon from '../../../assets/icons/GlobeIcon';

import { colourStyles, languages, saveLanguage } from './helpers';

export const LanguageSelector = ({ menuPortalTarget = document.body }) => {
  const { userData } = useStoreState(state => state.user);
  const { theme } = useStoreState(actions => actions.theme);
  const { setAuthLoader } = useStoreActions(actions => actions.authentication);
  const [selectedLanguage, setSelectedLanguage] = useState(
    JSON.parse(localStorage.getItem('language')) || languages[0]
  );

  const saveLanguageHandler = async e => {
    await saveLanguage(e, userData?.Id).then(() => {
      // silent err
    });
    window.location.reload();
  };

  const handeChange = e => {
    setAuthLoader(true);
    i18n.changeLanguage(e.value);
    localStorage.setItem('language', JSON.stringify(e));
    if (selectedLanguage === 'en') {
      setSelectedLanguage(e);
    } else if (selectedLanguage === 'pt') {
      setSelectedLanguage(e);
    } else if (selectedLanguage === 'it') {
      setSelectedLanguage(e);
    }
    saveLanguageHandler(e.value);
  };

  return (
    <Container>
      <GlobeIcon width="14" fill={theme.themes.colorPrimary} />
      <Select
        isSearchable={false}
        onChange={e => e?.value !== selectedLanguage?.value && handeChange(e)}
        defaultValue={selectedLanguage}
        options={languages}
        styles={colourStyles(theme)}
        menuPortalTarget={menuPortalTarget}
        components={{
          DropdownIndicator: () => (
            <ArrowDown
              fill={theme.themes.colorPrimary}
              extraStyle={{ marginLeft: 5, marginRight: 15 }}
            />
          )
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  border-radius: 5px;
  max-width: 240px;
  border: 1px solid #f1f1f1;
  padding-left: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.themes.colorPrimary};
`;

LanguageSelector.propTypes = {
  menuPortalTarget: PropTypes.object
};
